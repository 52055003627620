.body {
  background-color: #F9FAFC !important;
}

.react-datepicker__tab-loop {
  display: inline-block;
}

.MuiCard-root {
  background-color: #FFFFFF;
  color: #121828;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgb(100 116 139 / 6%), 0px 1px 2px rgb(100 116 139 / 10%);
}

.MuiAppBar-colorPrimary {
  padding: 8px 0px;
  background-color: #FFFFFF !important;
  color: #121828;
}

.MuiPaper-elevation1,
.MuiPaper-elevation3,
.MuiPaper-elevation4,
.MuiPaper-elevation0 {
  box-shadow: 0px 1px 4px rgb(100 116 139 / 12%) !important;
}

.MuiIconButton-colorInherit {
  color: #121828 !important;
}

.MuiAppBar-colorPrimary {
  color: #121828 !important;
}

.main-text {
  color: #121828;
}

.MuiDrawer-paperAnchorLeft {
  margin-top: 64px;
  padding: 0px;
  overflow-x: hidden;
}

.MuiAppBar-root {
  z-index: 1301 !important;
}

.MuiDialog-root {
  z-index: 1303 !important;
}

.MuiPopover-root {
  z-index: 1305 !important;
}

.activeItem::before {
  content: "";
  position: absolute;
  height: 16px;
  width: 4px;
  left: 0;
  top: 12px;
  background: #009ddd;
  border-radius: 0px 1.998px 1.998px 0px
}

.slide-toggle {
  left: 100%;
  top: 0px;
  bottom: 0px;
  position: absolute;
  outline: none;
}

.slide-togglebar {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%);
  bottom: 0px;
  top: 0px;
  left: -1px;
  opacity: 0.5;
  position: absolute;
  transition-duration: 0.22s;
  transition-property: left, opacity, width;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  width: 3px;
}

.toggle-line {
  height: 100%;
  width: 23px;
  padding: 0px;
  border: 0px;
  background-color: transparent;
  color: #fff
}

.toggle-btn {
  position: fixed;
  bottom: 3rem;
  border: 0px;
  border-radius: 50%;
  box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px;
  color: rgb(107, 119, 140);
  cursor: pointer;
  height: 24px;
  opacity: 1;
  outline: 0px;
  padding: 0px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
  width: 24px;
  transform-origin: 7px center;
}

.toggle-btn-r1 {
  transform: rotate(180deg);
}

.toggle-btn-r2 {
  transform: rotate(0deg);
  margin-left: -12px;
}

.toggle-btn:hover {
  background-color: #009ddd;
  color: #fff;
  transition: all 100ms;
}

.css-xwsnrl {
  display: block;
  height: 100%;
  transition: background-color 200ms ease 0s;
  width: 2px;
}

.css-z8pkji {
  position: absolute;
  inset: -8px -12px -8px -8px;
}

.css-pxzk9z>svg {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  color: var(--icon-primary-color);
  fill: var(--icon-secondary-color);
  vertical-align: bottom;
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
  .slide-toggle {
    display: none;
  }
}

@media only screen and(min-device-width:1024px)and (max-device-width:1224px) {}
